/* src/components/ContactUs.css */

/* Contact Us Section Styling */
.contact-us {
    background-color: rgba(0, 0, 0, 0.8); /* Light black background with opacity */
    padding: 80px 0;
    color: white; /* White text color on top of the background */
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* Subtle shadow for depth */
    margin-top: 50px; /* Optional: space from other sections above */
  }
  
  .contact-us h2 {
    font-size: 40px;
    font-weight: 700;
    color: #fff; /* Bold white heading */
    margin-bottom: 30px;
  }
  
  .contact-us p {
    font-size: 18px;
    color: #fff; /* Light text color */
    margin-bottom: 20px;
  }
  
  .contact-us .contact-details p {
    margin: 10px 0;
  }
  
  .contact-us .form-group label {
    font-size: 16px;
    font-weight: 600;
    color: #fff; /* Light label color */
  }
  
  .contact-us .form-control {
    border-radius: 8px;
    padding: 15px;
    border: none;
    font-size: 16px;
    background-color: rgba(255, 255, 255, 0.8); /* Slightly transparent white background */
    color: #333; /* Dark text color for input */
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }
  
  .contact-us .form-control:focus {
    background-color: #e6f7ff; /* Light blue background on focus */
    border-color: #007bff; /* Blue border on focus */
  }
  
  .contact-us .btn-primary {
    background-color: #ff5733; /* Vibrant Orange color */
    border: none;
    padding: 12px 24px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    transition: background-color 0.3s ease;
    margin-top: 30px; /* Move button down */
  }
  
  .contact-us .btn-primary:hover {
    background-color: #d45d2f; /* Slightly darker orange on hover */
  }
  
  .contact-us .contact-details {
    padding-right: 30px; /* Right-side padding for the contact details */
  }
  
  .contact-us .contact-details strong {
    font-size: 20px;
    color: #fff;
  }
  
  /* For responsiveness */
  @media (max-width: 768px) {
    .contact-us h2 {
      font-size: 30px;
    }
  
    .contact-us .contact-details {
      text-align: center;
      margin-bottom: 20px;
    }
  
    .contact-us .form-group {
      margin-bottom: 1.5rem;
    }
  
    .contact-us .form-control {
      font-size: 14px;
      padding: 12px; /* Slightly smaller padding for mobile */
    }
  
    .contact-us .btn-primary {
      padding: 10px 20px; /* Smaller button padding on mobile */
    }
  }
  