/* src/components/About.css */
.about {
    background-image: url('https://www.freepik.com/free-vector/abstract-blue-circle-black-background-technology_34386132.htm#fromView=keyword&page=1&position=7&uuid=47b00a7b-2063-4697-b12d-e37fd0b68323'); /* Replace with your image URL */
    background-size: cover;
    background-position: center;
    padding: 50px 0;
    color: white; /* To ensure the text is readable on dark backgrounds */
  }
  
  .about h2 {
    font-size: 2.5rem;
    margin-bottom: 30px;
    text-align: center;
  }
  
  .about p {
    font-size: 1.1rem;
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .about img {
    max-width: 100%;
    border-radius: 10px;
    margin-top: 30px;
  }
  
  /* New Grid Section */
  .about-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 40px;
  }
  
  .about-grid .grid-item {
    background-color: #fff;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .about-grid .grid-item img {
    border-radius: 10px;
    max-height: 200px;
    object-fit: cover;
    width: 100%;
  }
  
  @media (max-width: 768px) {
    .about-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 576px) {
    .about-grid {
      grid-template-columns: 1fr;
    }
  }
  