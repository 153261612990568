/* src/components/Services.css */

.services {
    background-color: #f8f9fa;
    padding: 50px 0;
  }
  
  .services h2 {
    font-size: 2.5rem;
    margin-bottom: 40px;
    text-align: center;
  }
  
  .services .card {
    border: none;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .services .card-body {
    text-align: center;
  }
  
  .services .card-title {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  
  .services .card-text {
    font-size: 1rem;
    color: #333;
  }
  
  .services .card:hover {
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
  }
  