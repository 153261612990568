/* General Section Styles */
.competitive-banner {
    background-color: #333;  /* Light black background color */
    color: #fff;  /* White text to contrast with the dark background */
    padding: 50px 0;
  }
  
  /* Container and Rows */
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  /* Left Content Styling */
  .left-content {
    padding-right: 30px;
    flex: 0 0 60%; /* Default width for large screens */
  }
  
  .competitve-banner-heading {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #fff;  /* White color for the heading */
  }
  
  .competitive-banner-content {
    font-size: 1.1rem;
    color: #ddd;  /* Slightly lighter text for content */
    margin-bottom: 30px;
    line-height: 1.6;
  }
  
  /* Count Background & Items */
  .count-background {
    margin-bottom: 30px;
  }
  
  .ui-components-count,
  .demos-count,
  .downloads-count {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .green-tick {
    margin-right: 10px;
  }
  
  .ui-components-count span,
  .demos-count span,
  .downloads-count span {
    font-size: 1rem;
    color: #fff;  /* White color for the count text */
  }
  
  /* Explore Link Styling */
  .explore-FT-comparison {
    margin-top: 30px;
  }
  
  .ft-competitive-link {
    background-color: #ff8e3d;
    color: white;
    padding: 12px 25px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: 600;
    transition: background-color 0.3s ease;
  }
  
  .ft-competitive-link:hover {
    background-color: #e77c1d;
  }
  
  /* Right Content (Image) */
  .competitive-banner-FT-image {
    position: relative;
    flex: 0 0 35%; /* Default width for large screens */
  }
  
  .ft-icon {
    width: 100%;
    height: auto;
  }
  
  /* Responsive Styling for Medium Screens (Tablets, Small Laptops) */
  @media (max-width: 991px) {
    .left-content {
      flex: 0 0 100%; /* Full width on medium screens */
      padding-right: 0;
    }
  
    .competitive-banner-FT-image {
      flex: 0 0 100%; /* Full width for image */
      margin-top: 20px;
    }
  
    .competitve-banner-heading {
      font-size: 1.75rem;
    }
  
    .competitive-banner-content {
      font-size: 1rem;
    }
  
    .ui-components-count span,
    .demos-count span,
    .downloads-count span {
      font-size: 0.95rem;
    }
  }
  
  /* Responsive Styling for Small Screens (Mobile Phones) */
  @media (max-width: 576px) {
    .competitve-banner-heading {
      font-size: 1.5rem;
      margin-bottom: 15px;
    }
  
    .competitive-banner-content {
      font-size: 0.9rem;
      margin-bottom: 20px;
    }
  
    .ui-components-count span,
    .demos-count span,
    .downloads-count span {
      font-size: 0.85rem;
    }
  
    .count-background {
      margin-bottom: 20px;
    }
  
    .ft-competitive-link {
      font-size: 0.9rem;
      padding: 10px 20px;
    }
  }
  