/* src/components/Navbar.css */

/* General Navbar Styling */
.navbar {
  background-color: #ffffff !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

/* Navbar Brand */
.navbar-brand {
  font-size: 1.8rem;
  font-weight: bold;
  color: #fff !important;
  text-transform: uppercase;
}

.navbar-brand:hover {
  color: #007bff !important;
}

/* Navbar Links (Desktop) */
.nav-link {
  color: #171616 !important;
  font-size: 1.1rem;
  padding: 10px 15px;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #007bff !important;
}

/* Navbar Button */
.sign-up-btn {
  background-color: #007bff;
  border-color: #007bff;
  font-size: 1rem;
  padding: 10px 20px;
  border-radius: 25px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.sign-up-btn:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

/* Offcanvas styling */
.offcanvas-body {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
}

.offcanvas-header {
  border-bottom: 1px solid #ddd;
}

.offcanvas-title {
  font-size: 1.5rem;
  color: #080808;
}

/* Mobile View Styling */
@media (max-width: 991px) {
  .navbar .navbar-collapse {
    display: none;
  }

  .navbar .navbar-toggler {
    display: block;
  }
}
