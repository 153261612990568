/* src/styles/BrandLogos.css */

.brand-logos-section {
    background-color: #f7f7f7;  /* Light background */
    padding: 50px 0;
    text-align: center;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .section-heading {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
  }
  
  .section-subheading {
    font-size: 1.1rem;
    color: #777;
    margin-bottom: 30px;
  }
  
  .logos-container {
    display: flex;
    overflow: hidden;  /* Hide logos outside the container */
    white-space: nowrap;  /* Keep logos in a single line */
  }
  
  .logo-item {
    flex: 0 0 auto;
    margin: 0 20px;
  }
  
  .logo-item img {
    width: 150px;
    height: auto;
    object-fit: contain;
  }
  
  @media (max-width: 768px) {
    .logo-item img {
      width: 120px;  /* Reduce logo size on smaller screens */
    }
  }
  