/* src/styles/Hero.css */

.hero {
  background-color: #ffffff; /* White background for the hero section */
  color: black; /* Text color set to black */
  padding: 100px 0; /* Padding for top and bottom */
  position: relative;
}

.hero h1 {
  font-size: 2.5rem;
  font-weight: bold;
  color: black; /* Set the header text color to black */
}

.hero p {
  font-size: 1.2rem;
  color: #333333; /* Lighter black for paragraph text */
  margin-top: 20px;
}

.hero .btn {
  background-color: #007bff;  /* Blue button color */
  border-color: #007bff;
  font-size: 1.1rem;
  padding: 12px 20px;
  border-radius: 5px;
  color: white; /* Text color inside the button */
}

.hero .btn:hover {
  background-color: #0056b3;  /* Darker blue on hover */
  border-color: #0056b3;
}

.google-play-btn {
  width: 147px;
  height: auto;
  margin-top: 0;
}

.hero img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .hero h1 {
    font-size: 2rem; /* Adjust the font size on smaller screens */
  }

  .hero p {
    font-size: 1rem;
  }

  .hero .btn {
    font-size: 1rem;
    padding: 10px 15px;
  }
}

/* Container for buttons and Google Play logo */
.btn-container {
  display: flex;
  align-items: center;  /* Align the button and logo vertically */
  gap: 15px;  /* Space between the button and logo */
  margin-top: 20px;
}

.get-started-btn {
  flex-shrink: 0;  /* Prevent the button from shrinking */
}

.google-play-container {
  flex-shrink: 0;  /* Prevent the logo from shrinking */
}
